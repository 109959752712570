import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import tachyons from 'tachyons-components'

const PostContainer = tachyons('div')`
w-100-l mb6
`
const PostTitle = tachyons('h3')`
lh-copy mb3 mt0
`
const TachLink = tachyons(Link)`
mb1 dib
`
const TitleLink = styled(TachLink)`
color: var(--textTitle);
text-decoration: none;
`
const StyledLink = styled(TachLink)`
color: var(--textPrimary);
text-decoration: none;
`

const PostExcerpt = tachyons('p')`
lh-copy
`
const PostMeta = tachyons('small')`
f6 db mb2
`
const StyledPostMeta = styled(PostMeta)`
  color: var(--textSecondary);
  font-weight: var(--fontSemiBold);
`;

const PostCard = ({posts}) => {
  return (
    <div className="container">
      {posts &&
        posts
          .map(({ node: post }) => (
            <PostContainer>
              <PostTitle>
                <TitleLink to={post.fields.slug}>
                  {post.frontmatter.title}
                </TitleLink>
                <StyledPostMeta>
                  {post.frontmatter.date} ·{" "}
                  {post.timeToRead !== 1
                    ? `${post.timeToRead} mins read`
                    : `${post.timeToRead} min read`}
                </StyledPostMeta>
              </PostTitle>
              <PostExcerpt>{post.excerpt}</PostExcerpt>
              <StyledLink to={post.fields.slug}>Read more →</StyledLink>
            </PostContainer>
          ))}
    </div>
  );
}

export default PostCard