import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticleList from '../components/ArticleList'
import tachyons from 'tachyons-components'
import Helmet from 'react-helmet'
import GlobalStyle from "../styles/GlobalStyle"

const PageContainer = tachyons('div')`
blog pv4-ns
`
const PageHeaderContainer = tachyons('div')`
wrap dt hr-bottom pb5 pt5 pt6-ns
`
const PageHeadingsContainer = tachyons('div')`
fl w-100 w-30-l tl
`
const PageHeadings = tachyons('h2')`
f3 fw6 mb2 mt0
`
const PageDescriptions = tachyons('div')`
fl w-100 w-70-l lh-copy mb0
`
const SectionArticles = tachyons('div')`
w-100 dt mt5
`
const ArticleWrapper = tachyons('div')`
wrap
`
const ButtonSecondary = tachyons(Link)`
button w-100 w-auto-ns no-underline br2 ph3 pv2 mt2 mb2 mt0-ns dib tc bg-secondary bs-secondary br-secondary btn-paginate
`
const ButtonDisabled = tachyons('div')`
button w-100 w-auto-ns no-underline br2 ph3 pv2 mt2 mb2 mt0-ns dib tc bg-secondary bs-secondary br-secondary o-30 btn-paginate disabled
`
const Pagination = tachyons('div')`
mb5 pt3 hr-top
`

const PaginationLink = props => {
  if (!props.page) {
    return (
      <ButtonSecondary role="button" to={`/blog/${props.url}`}>
        {`${props.text}`}
      </ButtonSecondary>
    )
  } else {
    return (
      <ButtonDisabled disabled>
        {props.text}
      </ButtonDisabled>
    )
  }
}

export default class BlogIndexPage extends React.Component {
  render() {

    const {location, pageContext} = this.props
    const {group, index, pageCount, first, last} = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString() + '/'
    const pageNumbers = new Array(pageCount).fill(undefined).map((_, index) => index + 1)

    return (
      <Layout>
        <GlobalStyle />
        <Helmet titleTemplate="Blog · Ajmal Afif">
          <meta
            name="description"
            content="I enjoy writing down and reflect on my experience. My goal is to write more about design, frontend and anything in between."
          />
        </Helmet>
        <PageContainer>
          <PageHeaderContainer>
            <PageHeadingsContainer>
              <PageHeadings>Blog</PageHeadings>
            </PageHeadingsContainer>
            <PageDescriptions>
              I enjoy writing down and reflect on my experience. My goal is to
              write more about design, frontend and anything in between.
            </PageDescriptions>
          </PageHeaderContainer>
          <SectionArticles>
            <ArticleWrapper>
              <ArticleList posts={group} />
              <Pagination>
                {!first && (
                  <PaginationLink
                    page={first}
                    url={previousUrl}
                    text="← Previous"
                  />
                )}
                {pageNumbers.map(number => {
                  const isActive =
                    location.pathname.indexOf(number) > -1 ||
                    (location.pathname === "/blog/" && number === 1);
                  return (
                    <PaginationLink
                      page={isActive}
                      url={`${number === 1 ? "" : number}`}
                      text={number}
                    />
                  );
                })}
                {!last && (
                  <PaginationLink page={last} url={nextUrl} text="Next →" />
                )}
              </Pagination>
            </ArticleWrapper>
          </SectionArticles>
        </PageContainer>
      </Layout>
    );
  }
}

BlogIndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const BlogIndexQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          timeToRead
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            image {
              childImageSharp {
                fluid(maxWidth: 640, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            tags
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;